<template>
  <div class="home">
    <div class="home-left">
      <Empty v-if="merchantList.length === 0"></Empty>
      <el-table
        :data="merchantList"
        class="home-list"
        style="width: 100%"
        v-else>
        <el-table-column
          prop="id"
          label="ID"
          width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="设施名称">
        </el-table-column>
        <el-table-column label="QRCode" width="100px">
          <template #default="scope">
            <el-popover
              placement="top-start"
              title="QRCode"
              :width="200"
              trigger="hover"
              v-if="scope.row.qrcode_path">
              <el-image
                slot="reference"
                style="width: 100%;"
                :src="`/api/admin/static/qrcode/${scope.row.id}/${scope.row.qrcode_path}`"
                fit="contain">
              </el-image>
              <!--              <img slot="reference" :src="`/static/qrcode/${scope.row.id}/${scope.row.qrcode_path}`" alt="Image">-->
              <template #reference>
                <el-icon>
                  <Picture/>
                </el-icon>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_active"
          label="状态"
          width="100px">
          <template #default="scope">
            <el-icon v-if="scope.row.is_active" color="green">
              <CircleCheckFilled/>
            </el-icon>
            <el-icon v-else color="red">
              <CircleCloseFilled/>
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button
              class="mt5"
              type="primary"
              link
              @click="openDocumentDialog(scope.row)">
              文档管理
            </el-button>
            <el-dropdown class="ml15" split-button type="primary" @click="editMerchant(scope.row)">
              编辑
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      link
                      @click="openChatHistoryDialog(scope.row)">
                      历史会话
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      link
                      @click="openWelcomeListDialog(scope.row)">
                      欢迎词
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      link
                      @click="openCommonQuestionListDialog(scope.row)">
                      常见问题
                    </el-button>
                  </el-dropdown-item>
                  <!--                  <el-dropdown-item>-->

                  <!--                    <el-button-->
                  <!--                      type="danger"-->
                  <!--                      link-->
                  <!--                      @click="deleteMerchant(scope.row)">-->
                  <!--                      删除-->
                  <!--                    </el-button>-->
                  <!--                  </el-dropdown-item>-->
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="documentDialog" title="文档管理" destroy-on-close draggable align-center>
      <div class="home-right" v-loading="loading">
        <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          label-width="120px"
          class="home-form"
          height="calc(100vh - 300px)"
        >
          <el-form-item
            label="设施文档"
            prop="content"
            required
          >
            <el-input
              type="textarea"
              rows="20"
              v-model="form.content"
              placeholder="请输入文档">
            </el-input>
          </el-form-item>
          <el-form-item
            label="提示词"
            prop="prompt"
          >
            <el-input
              type="textarea"
              rows="8"
              v-model="form.prompt"
              :placeholder="configInfo.openai_prompt">
            </el-input>
          </el-form-item>
          <el-form-item
            label="分片大小"
            prop="chunk_size"
          >
            <el-input-number
              v-model="form.chunk_size"
              :min="600"
              :max="3000"
              precision="0"
              placeholder="800">
            </el-input-number>
            <el-button class="ml10" type="primary" @click="documentPreview">分片预览</el-button>
          </el-form-item>
          <el-form-item label="停用词" prop="es_stop_words">
            <el-input
              type="textarea"
              rows="10"
              v-model="form.es_stop_words"
              :placeholder=configInfo.es_stop_words
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="documentDialog = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="merchantFormRef" :title="merchantForm.id === null ? '新增':'编辑'" width="40%"
               destroy-on-close
               draggable
               align-center>
      <span><el-form
        ref="merchantRuleFormRef"
        :model="merchantForm"
        :rules="merchantRules"
        label-width="150px"
        class="home-form"
        height="calc(100vh - 300px)"
      >
        <el-form-item label="设施名称" prop="name">
          <el-input v-model="merchantForm.name"></el-input>
        </el-form-item>
        <el-form-item label="副标题" prop="subtitle">
          <el-input type="textarea" rows="3" v-model="merchantForm.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="每日每客户访问限制" prop="daily_limit">
          <el-input-number v-model="merchantForm.daily_limit" min="1" step="1"></el-input-number>
        </el-form-item>
        <el-form-item label="问题字数限制" prop="query_limit">
          <el-input-number v-model="merchantForm.query_limit" min="1" step="1"></el-input-number>
        </el-form-item>
        <el-form-item label="设施状态" prop="is_active">
          <el-switch v-model="merchantForm.is_active" active-text="启用" inactive-text="禁用"></el-switch>
        </el-form-item>
        <el-form-item label="流式响应" prop="streaming">
          <el-switch v-model="merchantForm.streaming" active-text="启用" inactive-text="禁用"></el-switch>
        </el-form-item>
        <el-form-item label="用户头像" prop="user_avatar">
          <el-upload
            class="avatar-uploader"
            accept="image/*"
            :auto-upload="false"
            :limit="1"
            :show-file-list="false"
            :on-change="userAvatarHandleChange"
          >
              <img v-if="merchantForm.user_avatar_url" :src="merchantForm.user_avatar_url" class="avatar"
                   alt="userAvatar" width="110" height="110"/>
              <el-icon v-else class="avatar-uploader-icon"><Plus/></el-icon>
            </el-upload>
        </el-form-item>
        <el-form-item label="助手头像" prop="assistant_avatar">
          <el-upload
            class="avatar-uploader"
            accept="image/*"
            :auto-upload="false"
            :limit="1"
            :show-file-list="false"
            :on-change="assistantAvatarHandleChange"
          >
              <img v-if="merchantForm.assistant_avatar_url" :src="merchantForm.assistant_avatar_url" class="avatar"
                   alt="assistantAvatar" width="110" height="110"/>
              <el-icon v-else class="avatar-uploader-icon"><Plus/></el-icon>
            </el-upload>
        </el-form-item>
        </el-form>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="merchantFormRef = false">取消</el-button>
          <el-button type="primary" @click="createMerchant">
            {{ merchantForm.id === null ? '新增' : '确定' }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="chatHistoryDialog" title="历史会话" width="80%" destroy-on-close align-center
               class="no-padding">
      <template #header="{ close, titleId, titleClass }">
        <div class="clearfix pb20">
          <h4 :id="titleId" :class="titleClass">历史会话</h4>
        </div>
        <div class="clearfix">
          <div class="fl pb5 pr5 pt5">
            <el-select
              v-model="chatHistoryFilter.open_id"
              placeholder="请选择用户"
              @change="handleChatOpenIdChange"
              clearable
            >
              <el-option
                v-for="item in openIdList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="fl pb5 pr5 pt5">
            <el-input type="text" v-model="chatHistoryFilter.keyword" placeholder="请输入关键词" clearable
                      @keyup.enter="getChatHistory"></el-input>
          </div>
          <div class="fl pb5 pr5 pt5">
            <el-button
              type="primary"
              @click="getChatHistory">
              检索
            </el-button>
          </div>
          <div class="ml10 fr">
            <el-button type="success" @click="downloadChatHistory">CSV下载</el-button>
          </div>
        </div>
      </template>

      <div class="home-right" v-loading="chatHistoryInfo.loading">
        <Empty v-if="chatHistory.length === 0"></Empty>
        <el-table
          :data="chatHistory"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 600px)"
          v-else>
          <el-table-column
            prop="open_id"
            label="OpenID"
            min-width="200">
          </el-table-column>
          <el-table-column
            prop="query"
            label="问题"
            min-width="150">
          </el-table-column>
          <el-table-column
            prop="answer"
            label="回答"
            min-width="300">
          </el-table-column>
          <el-table-column
            prop="is_like"
            label="满意度"
            width="70">
            <template #default="scope">
              <el-icon v-if="scope.row.is_like === true" color="green">
                <i class="fa-solid fa-thumbs-up"></i>
              </el-icon>
              <el-icon v-else-if="scope.row.is_like === false" color="red">
                <i class="fa-solid fa-thumbs-down"></i>
              </el-icon>
            </template>
          </el-table-column>
          <el-table-column
            prop="input_token"
            label="输入Token"
            width="120">
            <template #header>
              <span>输入Token</span>
              <el-tooltip
                class="box-item"
                content="仅在禁用流式响应时有值"
                placement="top"
              >
                <el-icon class="ml5">
                  <Warning/>
                </el-icon>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="output_token"
            label="输出Token"
            width="120">
            <template #header>
              <span>输出Token</span>
              <el-tooltip
                class="box-item"
                content="仅在禁用流式响应时有值"
                placement="top"
              >
                <el-icon class="ml5">
                  <Warning/>
                </el-icon>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="total_cost"
            label="费用(USD)"
            width="120">
            <template #header>
              <span>费用(USD)</span>
              <el-tooltip
                class="box-item"
                content="仅在禁用流式响应时有值"
                placement="top"
              >
                <el-icon class="ml5">
                  <Warning/>
                </el-icon>
              </el-tooltip>
            </template>
            <template #default="scope">
              <!-- 修改显示内容 -->
              <span v-if="scope.row.total_cost != null">{{ scope.row.total_cost / 1000 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="会话时间"
            :formatter="dateFormat"
            width="200">
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="clearfix pt20 pb20">
          <el-pagination
            class="fr"
            background
            layout="prev, pager, next, sizes, total"
            :total="chatHistoryFilter.totalSize"
            @current-change="getChatHistory"
            @size-change="getChatHistory(true)"
            v-model:current-page="chatHistoryFilter.page"
            v-model:page-size="chatHistoryFilter.size"
            :page-sizes="[10, 20, 50, 100]"
          />
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="welcomeListDialog" title="欢迎信息" width="80%" destroy-on-close align-center
               class="no-padding">
      <div class="clearfix pb20">
        <div class="fr">
          <el-button type="primary" @click="newWelcome">追加</el-button>
        </div>
      </div>
      <div class="home-right" v-loading="chatHistoryInfo.loading">
        <Empty v-if="welcomeList.length === 0"></Empty>
        <el-table
          :data="welcomeList"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 600px)"
          v-else>
          <el-table-column
            label="序号"
            type="index"
            width="80">
          </el-table-column>
          <el-table-column
            prop="message"
            label="消息">
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="开始日"
            width="120">
          </el-table-column>
          <el-table-column
            prop="end_date"
            label="结束日"
            width="120">
          </el-table-column>
          <el-table-column
            prop="is_default"
            label="默认"
            width="60">
            <template #default="scope">
              <el-icon v-if="scope.row.is_default" color="green">
                <CircleCheckFilled/>
              </el-icon>
              <el-icon v-else color="red">
                <CircleCloseFilled/>
              </el-icon>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template #default="scope">
              <el-button
                type="primary"
                link
                @click="editWelcome(scope.row)">
                编辑
              </el-button>

              <el-button
                type="danger"
                link
                @click="deleteWelcome(scope.row)"
                v-if="scope.row.is_default !== true">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog v-model="welcomeFormRef" :title="welcomeForm.id === null ? '新增':'编辑'" width="30%"
               destroy-on-close
               draggable>
      <span>
        <el-form
          ref="welcomeRuleFormRef"
          :model="welcomeForm"
          :rules="welcomeRules"
          label-width="120px"
          class="home-form"
        >
        <el-form-item label="欢迎信息" prop="message">
          <el-input type="textarea" rows="3" v-model="welcomeForm.message"></el-input>
        </el-form-item>
        <el-form-item label="开始日期" prop="start_date">
          <el-date-picker
            v-model="welcomeForm.start_date"
            type="date"
            placeholder="选择日期"
            value-format="YYYY-MM-DD"
            :disabled=welcomeForm.is_default>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" prop="end_date">
          <el-date-picker
            v-model="welcomeForm.end_date"
            type="date"
            placeholder="选择日期"
            value-format="YYYY-MM-DD"
            :disabled=welcomeForm.is_default>
          </el-date-picker>
        </el-form-item>
        </el-form></span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="welcomeFormRef = false">取消</el-button>
          <el-button type="primary" @click="createWelcome">
            {{ welcomeForm.id === null ? '新增' : '确定' }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="commonQuestionListDialog" title="常见问题" width="80%" destroy-on-close align-center
               class="no-padding">
      <template #header="{ close, titleId, titleClass }">
        <div class="clearfix pb20">
          <h4 :id="titleId" :class="titleClass">常见问题</h4>
        </div>
        <div class="clearfix">
          <div class="fl pb5 pr5 pt5">
            <el-input type="text" v-model="commonQuestionFilter.keyword" placeholder="请输入关键词"
                      clearable
                      @keyup.enter="getCommonQuestionList"></el-input>
          </div>
          <div class="fl pb5 pr5 pt5">
            <el-button
              type="primary"
              @click="getCommonQuestionList">
              检索
            </el-button>
          </div>
          <div class="clearfix pb20">
            <div class="fr">
              <el-button type="primary" @click="newCommonQuestion">追加</el-button>
            </div>
          </div>
        </div>
      </template>
      <div class="home-right" v-loading="chatHistoryInfo.loading">
        <Empty v-if="commonQuestionList.length === 0"></Empty>
        <el-table
          :data="commonQuestionList"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 600px)"
          v-else>
          <el-table-column
            label="序号"
            type="index"
            width="80">
          </el-table-column>
          <el-table-column
            prop="question"
            label="问题">
          </el-table-column>
          <el-table-column
            prop="answer"
            label="回答">
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template #default="scope">
              <el-button
                type="primary"
                link
                @click="editCommonQuestion(scope.row)">
                编辑
              </el-button>

              <el-button
                type="danger"
                link
                @click="deleteCommonQuestion(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog v-model="commonQuestionFormRef" :title="commonQuestionForm.id === null ? '新增':'编辑'" width="30%"
               destroy-on-close
               draggable>
      <span>
        <el-form
          ref="commonQuestionRuleFormRef"
          :model="commonQuestionForm"
          :rules="commonQuestionRules"
          label-width="120px"
          class="home-form"
        >
        <el-form-item label="问题" prop="question">
          <el-input type="textarea" rows="3" v-model="commonQuestionForm.question"></el-input>
        </el-form-item>
        <el-form-item label="回答" prop="answer">
           <el-input type="textarea" rows="3" v-model="commonQuestionForm.answer"></el-input>
        </el-form-item>
        </el-form></span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="commonQuestionFormRef = false">取消</el-button>
        <el-button type="primary" @click="createCommonQuestion">
          {{ commonQuestionForm.id === null ? '新增' : '确定' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="documentPreviewDialog" title="分片预览" width="80%" destroy-on-close align-center
               class="no-padding">

      <div class="home-right" v-loading="loading">
        <Empty v-if="documentPreviewList.length === 0"></Empty>
        <el-table
          :data="documentPreviewList"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 300px)"
          v-else>
          <el-table-column
            label="序号"
            type="index"
            width="60">
          </el-table-column>
          <el-table-column
            prop="text"
            label="文本">
            <template #default="scope">
              <p class="preview-cell">{{ scope.row.text }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="clearfix pt5 pb5">
        </div>
      </template>
    </el-dialog>

    <div class="none">
      <a ref="downloadRef"></a>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { dayjs, ElMessage, ElMessageBox, genFileId } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {

    // merchant form

    const merchantList = ref([])
    const merchant = ref(null)
    const merchantFormRaw = reactive({
      id: null,
      name: null,
      is_active: true,
      streaming: true,
      subtitle: null,
      user_avatar: null,
      assistant_avatar: null,
      daily_limit: 100,
      query_limit: 100,
    })
    const merchantForm = reactive(JSON.parse(JSON.stringify(merchantFormRaw)))
    const merchantFormRef = ref(false)

    const merchantWelcomeContent = computed(() => {
      return `你好，我是${merchantForm.name}的小助手`
    })

    const merchantRuleFormRef = ref(null)

    const merchantRules = reactive({
      name: [{ required: true, message: '请输入设施名称', trigger: 'blur' },],
      subtitle: [{ required: true, message: '请输入副标题', trigger: 'blur' },],
      daily_limit: [{ required: true, message: '请输入每日访问限制', trigger: 'blur' },],
      query_limit: [{ required: true, message: '请输入问题字数限制', trigger: 'blur' },],
    })
    const getMerchantList = () => {
      loading.value = true
      authApi.getMerchantList({ name: searchName.value }).then(res => {
        console.log(res)
        merchantList.value = res.data
      }).finally(() => {
        loading.value = false
      })
    }
    const newMerchant = () => {
      Object.keys(merchantFormRaw).forEach(key => {
        merchantForm[key] = merchantFormRaw[key]
      })
      merchantFormRef.value = true
    }
    const editMerchant = (item) => {
      console.log(item)
      Object.keys(merchantFormRaw).forEach(key => {
        merchantForm[key] = item[key]
      })
      merchantForm.user_avatar_url = `/api/admin/static/avatar/${item.id}/${item.user_avatar}`
      merchantForm.assistant_avatar_url = `/api/admin/static/avatar/${item.id}/${item.assistant_avatar}`
      merchantFormRef.value = true
    }
    const deleteMerchant = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除设施',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        loading.value = true
        authApi.deleteMerchant(item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getMerchantList()
        }).finally(() => {
          loading.value = false
        })
      })

    }
    const createMerchant = () => {
      merchantRuleFormRef.value.validate((valid) => {
        if (valid) {
          merchantFormRef.value = false
          loading.value = true
          if (merchantForm.id === null) {
            authApi.createMerchant(merchantForm).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              getMerchantList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updateMerchant(merchantForm.id, merchantForm).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              getMerchantList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    const searchName = ref(null)

    // 文件 form

    const form = reactive({
      merchant_id: null,
      content: null,
      prompt: null,
      chunk_size: null,
      es_stop_words: null,

    })
    const loading = ref(false)
    const rules = reactive({
      merchant_id: [
        { required: true, message: '请选择设施', trigger: 'blur' },
      ],
      content: [
        { required: true, message: '请填写文本', trigger: 'blur' },
      ]
    })
    const ruleFormRef = ref(null)
    const upload = ref(null)
    const handleExceed = (files) => {
      upload.value.clearFiles()
      const file = files[0]
      file.uid = genFileId()
      upload.value.handleStart(file)
    }
    const handleChange = (file, fileList) => {
      console.log(file)
      form.file = file.raw
    }
    const submit = () => {
      ruleFormRef.value.validate((valid) => {
        if (valid) {
          loading.value = true
          console.log(form)
          authApi.upload(form).then(res => {
            console.log(res)
            ElMessage({
              message: '上传成功',
              type: 'success',
            })
            getMerchantList()
            documentDialog.value = false
          }).finally(() => {
            loading.value = false
          })
        }
      })
    }

    const downloadDocument = () => {
      authApi.downloadDocument(merchant.value.id, merchant.value.document.id).then(res => {
        download(res)
      })

    }

    const documentDialog = ref(false)
    const openDocumentDialog = (item) => {
      merchant.value = item
      form.merchant_id = item.id
      form.content = item.document?.content || null
      form.prompt = item.document?.prompt || null
      form.chunk_size = item.document?.chunk_size || null
      documentDialog.value = true
    }
    const downloadRef = ref(null)
    const download = (res) => {
      debugger
      let fileName = ''
      const disposition = res.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        // 正規表現
        const filenameRegex = /filename[^;=\n]=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          // matches[1]でとれる⇒ filename*=utf-8''201911%E3%83%87%E3%83%BC%E3%82%BF.csv;
          // 不要文字列を消して、デコードしてサーバからのファイル名を取得
          fileName = decodeURI(
            matches[1].replace(/['"]/g, '').replace('utf-8', '')
          )
        }
      }
      const fileURL = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/octet-stream' })
      )
      console.log(fileURL)
      downloadRef.value.href = fileURL
      downloadRef.value.setAttribute('download', fileName)
      downloadRef.value.click()
      window.URL.revokeObjectURL(fileURL)
    }

    const configInfo = reactive({
      openai_prompt: null,
      es_stop_words: null,
    })

    const getOpenaiConfig = () => {
      loading.value = true
      authApi.getOpenaiConfig().then(res => {
        console.log(res)
        configInfo.openai_prompt = res.data.openai_prompt
        configInfo.es_stop_words = res.data.es_stop_words
      }).finally(() => {
        loading.value = false
      })
    }
    // document preview
    const documentPreviewDialog = ref(false)
    const documentPreviewList = ref([])
    const documentPreview = () => {
      ruleFormRef.value.validate((valid) => {
        if (valid) {
          loading.value = true
          console.log(form)
          authApi.uploadPreview(form).then(res => {
            documentPreviewList.value = res.data
            documentPreviewDialog.value = true
          }).finally(() => {
            loading.value = false
          })
        }
      })
    }
    // 会话记录
    const chatHistoryFilter = reactive({
      open_id: null,
      page: 1,
      size: 10,
      keyword: null,
      totalSize: 0,
    })
    const chatHistoryInfo = reactive({
      totalSize: 0,
      loading: false,
    })
    const handleChatOpenIdChange = (file, fileList) => {
      if (file === '') {
        chatHistoryFilter.open_id = null
      }
      getChatHistory()
    }
    const openIdList = ref([])
    const chatHistory = ref([])
    const chatHistoryDialog = ref(false)
    const openChatHistoryDialog = (item) => {
      merchant.value = item
      getChatHistory()
      chatHistoryDialog.value = true
    }

    const getChatHistory = (reset) => {
      if (reset === true) {
        chatHistoryFilter.page = 1
      }
      // chatHistory.value = []
      chatHistoryInfo.loading = true
      authApi.getChatHistory(merchant.value.id, chatHistoryFilter).then(res => {
        chatHistory.value = res.data.list
        chatHistoryFilter.totalSize = res.data.total
        openIdList.value = res.data.open_ids.map(item => {
          return {
            value: item,
            label: item,
          }
        })
      }).finally(() => {
        chatHistoryInfo.loading = false
      })
    }
    const dateFormat = (row, column, cellValue) => {
      return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
    }
    const downloadChatHistory = () => {
      authApi.downloadChatHistory(merchant.value.id, chatHistoryFilter).then(res => {
        download(res)
      })
    }

    // welcome
    const welcomeList = ref([])
    const welcomeListDialog = ref(false)
    const openWelcomeListDialog = (item) => {
      merchant.value = item
      getWelcomeList()
      welcomeListDialog.value = true
    }

    const getWelcomeList = (reset) => {
      // chatHistory.value = []
      chatHistoryInfo.loading = true
      authApi.getWelcomeList(merchant.value.id).then(res => {
        welcomeList.value = res.data
      }).finally(() => {
        chatHistoryInfo.loading = false
      })
    }

    const welcomeFormRaw = reactive({
      id: null,
      message: null,
      start_date: new Date(),
      end_date: null,
      is_default: false,
    })
    const welcomeForm = reactive(JSON.parse(JSON.stringify(welcomeFormRaw)))
    const welcomeFormRef = ref(false)

    const welcomeRuleFormRef = ref(null)

    const welcomeRules = reactive({
      message: [
        { required: true, message: '请输入欢迎信息', trigger: 'blur' },
      ],
      start_date: [
        { required: true, message: '请选择开始日期', trigger: 'blur' },
      ],
      end_date: [
        { required: true, message: '请选择结束日期', trigger: 'blur' },
      ],
    })

    const newWelcome = () => {
      Object.keys(welcomeFormRaw).forEach(key => {
        welcomeForm[key] = welcomeFormRaw[key]
      })
      welcomeFormRef.value = true
    }
    const editWelcome = (item) => {
      console.log(item)
      Object.keys(welcomeFormRaw).forEach(key => {
        welcomeForm[key] = item[key]
      })
      welcomeForm.message = item.message
      welcomeFormRef.value = true
    }
    const deleteWelcome = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除欢迎信息',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        console.log(item.id)
        loading.value = true
        authApi.deleteWelcome(merchant.value.id, item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getWelcomeList()
        }).finally(() => {
          loading.value = false
        })
      })

    }
    const createWelcome = () => {
      welcomeRuleFormRef.value.validate((valid) => {
        if (valid) {
          let verify_welcome = welcomeList.value.find(item => {
            const result = item.end_date >= welcomeForm.start_date && item.start_date <= welcomeForm.end_date && item.is_default === false;
            return welcomeForm.id !== null ? (result && item.id !== welcomeForm.id) : result;
          })
          if (verify_welcome !== undefined && welcomeForm.is_default === false) {
            ElMessage({
              message: '该时间段已存在欢迎信息',
              type: 'error',
            })
            return
          }
          welcomeFormRef.value = false
          loading.value = true
          if (welcomeForm.id === null) {
            authApi.createWelcome(merchant.value.id, welcomeForm).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              getWelcomeList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updateWelcome(merchant.value.id, welcomeForm.id, welcomeForm).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              getWelcomeList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    const userAvatarHandleChange = (file, fileList) => {
      if (file === '') {
        merchantForm.user_avatar = null
      }
      console.log(file)
      const userReader = new FileReader();

      userReader.onload = () => {
        merchantForm.user_avatar = userReader.result;
        merchantForm.user_avatar_url = userReader.result;
      };
      userReader.readAsDataURL(file.raw);
    }

    const assistantAvatarHandleChange = (file, fileList) => {
      if (file === '') {
        merchantForm.assistant_avatar = null
      }
      console.log(file)
      const assistantReader = new FileReader();
      assistantReader.readAsDataURL(file.raw);
      assistantReader.onload = () => {
        merchantForm.assistant_avatar = assistantReader.result;
        merchantForm.assistant_avatar_url = assistantReader.result;
      };
      assistantReader.readAsDataURL(file.raw);
    }

    // common question
    const commonQuestionList = ref([])
    const commonQuestionListDialog = ref(false)
    const openCommonQuestionListDialog = (item) => {
      merchant.value = item
      getCommonQuestionList()
      commonQuestionListDialog.value = true
    }

    const getCommonQuestionList = (reset) => {
      // chatHistory.value = []
      chatHistoryInfo.loading = true
      authApi.getCommonQuestionList(merchant.value.id, commonQuestionFilter).then(res => {
        commonQuestionList.value = res.data
      }).finally(() => {
        chatHistoryInfo.loading = false
      })
    }

    const commonQuestionFormRaw = reactive({
      id: null,
      question: null,
      answer: null,
    })
    const commonQuestionForm = reactive(JSON.parse(JSON.stringify(commonQuestionFormRaw)))
    const commonQuestionFormRef = ref(false)

    const commonQuestionRuleFormRef = ref(null)

    const commonQuestionRules = reactive({
      question: [
        { required: true, message: '请输入问题', trigger: 'blur' },
      ],
      answer: [
        { required: true, message: '请输入答案', trigger: 'blur' },
      ],
    })

    const commonQuestionFilter = reactive({
      keyword: null,
    })

    const newCommonQuestion = () => {
      Object.keys(commonQuestionFormRaw).forEach(key => {
        commonQuestionForm[key] = commonQuestionFormRaw[key]
      })
      commonQuestionFormRef.value = true
    }
    const editCommonQuestion = (item) => {
      console.log(item)
      Object.keys(commonQuestionFormRaw).forEach(key => {
        commonQuestionForm[key] = item[key]
      })
      commonQuestionFormRef.value = true
    }
    const deleteCommonQuestion = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除常见问题',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        console.log(item.id)
        loading.value = true
        authApi.deleteCommonQuestion(merchant.value.id, item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getCommonQuestionList()
        }).finally(() => {
          loading.value = false
        })
      })

    }
    const createCommonQuestion = () => {
      commonQuestionRuleFormRef.value.validate((valid) => {
        if (valid) {
          commonQuestionFormRef.value = false
          loading.value = true
          if (commonQuestionForm.id === null) {
            authApi.createCommonQuestion(merchant.value.id, commonQuestionForm).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              getCommonQuestionList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updateCommonQuestion(merchant.value.id, commonQuestionForm.id, commonQuestionForm).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              getCommonQuestionList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    onMounted(() => {
      getMerchantList()
      getOpenaiConfig()
    })
    return {
      form,
      loading,
      rules,
      ruleFormRef,
      upload,
      handleExceed,
      submit,
      handleChange,
      merchantList,
      merchant,
      merchantForm,
      merchantFormRef,
      newMerchant,
      createMerchant,
      merchantRuleFormRef,
      merchantRules,
      downloadDocument,
      editMerchant,
      deleteMerchant,
      documentDialog,
      openDocumentDialog,
      downloadRef,
      searchName,
      getMerchantList,
      configInfo,
      chatHistory,
      chatHistoryDialog,
      openChatHistoryDialog,
      chatHistoryFilter,
      handleChatOpenIdChange,
      openIdList,
      getChatHistory,
      chatHistoryInfo,
      dateFormat,
      downloadChatHistory,
      merchantWelcomeContent,
      welcomeList,
      welcomeListDialog,
      openWelcomeListDialog,
      newWelcome,
      createWelcome,
      welcomeForm,
      welcomeFormRef,
      welcomeRuleFormRef,
      welcomeRules,
      editWelcome,
      deleteWelcome,
      userAvatarHandleChange,
      assistantAvatarHandleChange,
      commonQuestionList,
      commonQuestionListDialog,
      openCommonQuestionListDialog,
      newCommonQuestion,
      createCommonQuestion,
      commonQuestionForm,
      commonQuestionFormRef,
      commonQuestionRuleFormRef,
      commonQuestionRules,
      commonQuestionFilter,
      getCommonQuestionList,
      editCommonQuestion,
      deleteCommonQuestion,

      documentPreviewDialog,
      documentPreviewList,
      documentPreview,
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}
</style>
